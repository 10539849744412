import request from '@/utils/request'


// 查询小助手消息列表
export function listAssistant(query) {
  return request({
    url: '/message/assistant/list',
    method: 'get',
    params: query
  })
}

// 查询小助手消息分页
export function pageAssistant(query) {
  return request({
    url: '/message/assistant/page',
    method: 'get',
    params: query
  })
}

// 查询小助手消息详细
export function getAssistant(data) {
  return request({
    url: '/message/assistant/detail',
    method: 'get',
    params: data
  })
}

// 新增小助手消息
export function addAssistant(data) {
  return request({
    url: '/message/assistant/add',
    method: 'post',
    data: data
  })
}

// 修改小助手消息
export function updateAssistant(data) {
  return request({
    url: '/message/assistant/edit',
    method: 'post',
    data: data
  })
}

// 删除小助手消息
export function delAssistant(data) {
  return request({
    url: '/message/assistant/delete',
    method: 'post',
    data: data
  })
}
